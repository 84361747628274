





































































































































import draggable from 'vuedraggable';
import { defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { EntryProperty, EntryPropertyFormData } from '@/admin/form';
import { uiTypes } from '@/admin/uiType';
import { getOptionsTitle } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';

export default defineComponent({
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array as PropType<EntryProperty[]>,
      required: true,
    },
    type: {
      type: String,
      default: 'form',
    },
    isAllowUpdate: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const items: Ref<EntryProperty[]> = ref([...props.value]);
    const formData: Ref<EntryPropertyFormData | null> = ref(null);
    const active = ref(false);
    const { confirmDialog } = useConfirm();

    const update = () => {
      context.emit('input', items.value);
    };

    const add = () => {
      formData.value = new EntryPropertyFormData({});
      active.value = true;
    };

    const edit = (item: EntryProperty) => {
      formData.value = new EntryPropertyFormData(item);
      active.value = true;
    };

    const cancel = () => {
      active.value = false;
    };

    const save = () => {
      if (!formData.value) return;
      if (!formData.value.title) {
        alert('タイトルは必須です');
        return;
      }
      if (formData.value.key) {
        const item = items.value.find((item) => item.key === formData.value?.key);
        if (!item) {
          alert('no item');
          return;
        }
        const index = items.value.indexOf(item);
        items.value.splice(index, 1, formData.value.createEntryProperty());
      } else {
        formData.value.key =
          'prop-' +
          Math.random()
            .toString()
            .slice(-6);
        items.value.push(formData.value.createEntryProperty());
      }
      active.value = false;

      update();
    };

    const remove = async (item: EntryProperty) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      const index = items.value.indexOf(item);
      items.value.splice(index, 1);
      update();
    };

    // アカウント情報のみ変更画面に表示するかのプロパティ（isHiddenInUpdate）変更できる
    const headers = [
      { text: '', value: 'handle', sortable: false, class: !props.isAllowUpdate ? 'd-none' : '' },
      { text: 'タイトル', value: 'title', sortable: false },
      { text: '備考', value: 'note', sortable: false },
      { text: '必須', value: 'required', sortable: false },
      { text: 'UIタイプ', value: 'uiType', sortable: false },
      {
        text: '変更可否',
        value: 'isHiddenInUpdate',
        sortable: false,
        class: props.type !== '_userProperties' ? 'd-none' : '',
      },
      { text: '', value: 'actions', sortable: false },
    ];

    const uiTypeOptions = [
      { text: 'テキストフィールド', value: uiTypes.TEXT },
      { text: 'テキストフィールド（複数行）', value: uiTypes.TEXTAREA },
      { text: 'セレクトボックス', value: uiTypes.SELECTBOX },
      { text: 'チェックボックス', value: uiTypes.CHECKBOX },
      { text: 'ラジオボタン', value: uiTypes.RADIO },
    ];

    return {
      items,
      formData,
      active,
      update,
      add,
      edit,
      cancel,
      save,
      remove,
      headers,
      uiTypeOptions,
      getOptionsTitle,
    };
  },
});
