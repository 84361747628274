import core from '@/admin/core';
import { User, useUser } from '@/admin/user';
import { UiType, uiTypes } from '@/admin/uiType';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';

interface Permission {
  acceptAllMembers: boolean;
  acceptAllUsers: boolean;
  deny: boolean;
  planIds?: string[];
  productIds?: string[];
  giftIds?: string[];
  seasonIds?: number[];
}

interface SaveFormRequest {
  formId?: string;
  formName: string;
  entryStartDate: number;
  entryEndDate: number;
  entrySchema: {
    [key: string]: {
      title: string;
      note?: string;
      type: 'string' | 'array';
      items?: object;
      properties?: object;
      required?: boolean;
      order: number;
      uiType: UiType;
      uiOptionsString?: string;
      isHiddenInUpdate?: boolean;
      image?: string;
    };
  };
  isAllowMultiEntries: boolean;
  entryAuthority: Permission;
  headerImage?: string;
  description?: string;
  confirmation?: string;
  isRequiredShippingAddress: boolean;
}
interface ShippingDetailProperty {
  shippingDetailId: string;
  username: string;
  historyIds: string[];
  shippingAddressId: string;
  name: string;
  postalCode: string;
  prefecture: string;
  city: string;
  blockNumber: string;
  buildingName: string;
  roomNumber: string;
  phoneNumber: string;
  shippingSourceType: string;
  isShippingAddressConfirmed: boolean;
  createDate: number;
}
interface GetFormResponse extends SaveFormRequest {
  formId: string;
  latestEntryDate?: number;
}

export interface EntryProperty {
  key: string;
  title: string;
  note: string;
  required: boolean;
  uiType: UiType;
  uiOptionsString: string;
  isHiddenInUpdate?: boolean;
}

export class Form {
  formId?: string;
  formName: string;
  headerImage: string;
  description: string;
  confirmation: string;
  entryStartDate: DisplayDate;
  entryEndDate: DisplayDate;
  entryProperties: EntryProperty[];
  shippingDetail?: ShippingDetailProperty;
  latestEntryDate: DisplayDate | string;
  isAllowMultiEntries: boolean;
  isAllowUpdateProperties: boolean;
  entryAuthority: Permission;
  isRequiredShippingAddress: boolean;

  constructor(props?: GetFormResponse) {
    const _entryAuthority: Permission = {
      planIds: [],
      productIds: [],
      giftIds: [],
      seasonIds: [],
      acceptAllUsers: props?.formId === '_userProperties',
      acceptAllMembers: false,
      deny: false,
    };

    if (props) {
      this.formId = props.formId;
      this.formName = props.formName;
      this.headerImage = props.headerImage || '';
      this.description = props.description || '';
      this.confirmation = props.confirmation || '';
      this.entryStartDate = new DisplayDate(props.entryStartDate);
      this.entryEndDate = new DisplayDate(props.entryEndDate);
      this.entryProperties = Object.keys(props.entrySchema)
        .sort((keyA, keyB) => {
          return props.entrySchema[keyA].order < props.entrySchema[keyB].order ? -1 : 1;
        })
        .map((key) => {
          return {
            key,
            title: props.entrySchema[key].title,
            note: props.entrySchema[key].note || '',
            required: props.entrySchema[key].required || false,
            uiType: props.entrySchema[key].uiType,
            uiOptionsString: props.entrySchema[key].uiOptionsString || '',
            isHiddenInUpdate: props.entrySchema[key].isHiddenInUpdate || false,
          };
        });
      this.latestEntryDate = props.latestEntryDate ? new DisplayDate(props.latestEntryDate) : '';
      this.isAllowMultiEntries = props.isAllowMultiEntries;
      this.isAllowUpdateProperties = this.entryStartDate.value ? this.entryStartDate.value > Date.now() : false;
      this.entryAuthority = props.entryAuthority || _entryAuthority;
      this.isRequiredShippingAddress = props.isRequiredShippingAddress;
    } else {
      this.formName = '';
      this.headerImage = '';
      this.description = '';
      this.confirmation = '';
      this.entryStartDate = new DisplayDate();
      this.entryEndDate = new DisplayDate();
      this.entryProperties = [];
      this.latestEntryDate = '';
      this.isAllowMultiEntries = false;
      this.isAllowUpdateProperties = true;
      this.entryAuthority = _entryAuthority;
      this.isRequiredShippingAddress = false;
    }
  }

  createRequestData(): SaveFormRequest {
    if (!this.formName || this.entryStartDate.value === undefined || this.entryEndDate.value === undefined) {
      throw 'タイトル、受付開始日、受付終了日で未入力があります';
    } else if (!this.entryProperties.length) {
      throw '入力項目を追加してください';
    } else if (
      (this.formId !== '_userProperties' || !this.entryAuthority.acceptAllUsers) &&
      !this.entryAuthority.acceptAllMembers &&
      !this.entryAuthority.planIds?.length &&
      !this.entryAuthority.giftIds?.length &&
      !this.entryAuthority.productIds?.length &&
      !this.entryAuthority.seasonIds?.length
    ) {
      throw '権限で未入力があります';
    }
    const request: SaveFormRequest = {
      formId: this.formId,
      formName: this.formName,
      entryStartDate: this.entryStartDate.value,
      entryEndDate: this.entryEndDate.value,
      entrySchema: {},
      isAllowMultiEntries: this.isAllowMultiEntries,
      entryAuthority: this.entryAuthority,
      isRequiredShippingAddress: this.isRequiredShippingAddress,
    };
    if (this.headerImage) request.headerImage = this.headerImage;
    if (this.description) request.description = this.description;
    if (this.confirmation) request.confirmation = this.confirmation;
    this.entryProperties.forEach((property, index) => {
      request.entrySchema[property.key] = {
        title: property.title,
        note: property.note,
        type: property.uiType === uiTypes.CHECKBOX ? 'array' : 'string',
        required: property.required,
        order: index,
        uiType: property.uiType,
        uiOptionsString: property.uiOptionsString,
        isHiddenInUpdate: property.isHiddenInUpdate || false,
      };
      if (property.uiType === uiTypes.CHECKBOX) {
        request.entrySchema[property.key]['items'] = { type: 'string' };
      }
    });
    return request;
  }
}

export class EntryPropertyFormData {
  key?: string;
  title: string;
  note: string;
  required: boolean;
  uiType: UiType;
  uiOptionsString: string;
  isHiddenInUpdate?: boolean;

  constructor(props: {
    key?: string;
    title?: string;
    note?: string;
    required?: boolean;
    uiType?: UiType;
    uiOptionsString?: string;
    isHiddenInUpdate?: boolean;
  }) {
    this.key = props.key;
    this.title = props.title || '';
    this.note = props.note || '';
    this.required = props.required || false;
    this.uiType = props.uiType || uiTypes.TEXT;
    this.uiOptionsString = props.uiOptionsString || '';
    this.isHiddenInUpdate = props.isHiddenInUpdate || false;
  }

  createEntryProperty(): EntryProperty {
    if (!this.key) {
      throw new Error('no key');
    }
    return {
      key: this.key,
      title: this.title,
      note: this.note,
      required: this.required,
      uiType: this.uiType,
      uiOptionsString: this.uiOptionsString,
      isHiddenInUpdate: this.isHiddenInUpdate || false,
    };
  }
}

interface GetEntryResponse {
  formId: string;
  entryKey: string;
  entryDate: number;
  entry: {
    [key: string]: string;
  };
  shippingDetail?: ShippingDetailProperty;
}

export class Entry {
  entryDate: DisplayDate;
  entry: {
    [key: string]: string;
  };
  user: User | {};
  shippingDetail?: ShippingDetailProperty;

  constructor(response: GetEntryResponse, user?: User) {
    this.entryDate = new DisplayDate(response.entryDate);
    this.entry = response.entry;
    this.user = user || {};
    this.shippingDetail = response.shippingDetail;
  }
}

const getForms = async () => {
  if (myAttributes.myRequestPermissions?.forms) {
    const result = await core.httpClient.get('/admin/public/forms');
    return result.data
      .filter((formPayload: GetFormResponse) => formPayload.formId !== '_userProperties')
      .map((formPayload: GetFormResponse) => new Form(formPayload));
  } else return [];
};

const getForm = async (formId?: string) => {
  if (!formId) {
    return new Form();
  } else {
    if (myAttributes.myRequestPermissions?.forms) {
      const result = await core.httpClient.get(`/admin/public/forms/${formId}`);
      return new Form(result.data as GetFormResponse);
    } else return null;
  }
};

const saveForm = async (form: Form) => {
  const data = form.createRequestData();
  const method = data.formId ? 'PUT' : 'POST';
  const url = `/admin/public/forms/${data.formId || ''}`;
  await core.httpClient.request({ method, url, data });
};

const getEntries = async (formId: string) => {
  const { getAllUsers } = useUser();

  if (myAttributes.myRequestPermissions?.entries) {
    const [getEntriesResponse, users] = await Promise.all([
      core.httpClient.get(`/admin/restrict/entries?formId=${formId}`),
      getAllUsers(),
    ]);

    const entries = getEntriesResponse.data as GetEntryResponse[];

    return entries.map((entry) => {
      const user = users.find((user) => user.userId === entry.entryKey);
      return new Entry(entry, user);
    });
  } else return [];
};

export const useForm = () => {
  return { getForms, getForm, saveForm, getEntries };
};
